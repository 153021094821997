.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
}
  
.active, .accordion:hover {
    background-color: #ccc; 
}
  
.panel {
    padding: 0 18px;
    display: none;
    background-color: white;
    overflow: hidden;
}

.plusBtn{
    float: right
}