html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
a {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

/* Style the header */
/* .header { */
    /* padding: 10px 16px;
    background: #555;
    color: #f1f1f1; */
  /* } */
  
  /* Page content */
  /* .content {
    padding: 16px; */
  /* } */
  
  /* The sticky class is added to the header with JS when it reaches its scroll position */
  .sticky {
    position: fixed;
    top: 0;
    width: 100%
  }
  
  /* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
  /* .sticky + .content {
    padding-top: 102px; */
  /* } */ 